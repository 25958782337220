import { useContext, useRef } from 'react';
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, ThemeContext } from '@forma/forma-ui-kit';

import styles from './reviews.module.css';

const Reviews = ({ title, items }) => {
  const ref = useRef(null);
  const { viewport } = useContext(ThemeContext);
  const isPhone = viewport === 'phone';

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>

      <div className={styles.slider}>
        {(!isPhone && items.length > 2) && (
          <Button viewStyle="secondary" className={classNames(styles.sliderButton, styles.prev)} onClick={() => ref?.current?.slickPrev()}>
            <ReactSVG src="/icons/chevron-left.svg" alt="Prev" wrapper="span" />
          </Button>
        )}
        <Slider
          arrows={false}
          infinite={false}
          dots={isPhone}
          slidesToShow={2}
          responsive={[
            {
              breakpoint: 800,
              settings: { slidesToShow: 1 }
            },
            {
              breakpoint: 575,
              settings: { slidesToShow: 1 }
            },
          ]}
          ref={ref}
        >
          {items.map(({ review, author }, index) => (
            <div className={styles.review} key={index}>
              <div className={styles.reviewMessage}>{review}</div>
              {author && (
                <div className={styles.author}>
                  <div>
                    <div className={styles.authorName}>{author.name}</div>
                    <div className={styles.authorPosition}>{author.position}</div>
                  </div>
                  <img src={author.image} className={styles.authorImage} alt={author.name} />
                </div>
              )}
            </div>
          ))}
        </Slider>
        {(!isPhone && items.length > 2) && (
          <Button viewStyle="secondary" className={classNames(styles.sliderButton, styles.next)} onClick={() => ref?.current?.slickNext()}>
            <ReactSVG src="/icons/chevron-right.svg" alt="Next" wrapper="span" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Reviews;
