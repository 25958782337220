import { Trans } from 'next-i18next';

import styles from './homepage-title.module.css';

const HomepageTitle2 = () => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Trans
          i18nKey="move_your_templates"
          components={{ accent: <span className="accent-text" /> }}
        />
      </div>
      <img src="/images/move_templates.svg" className={styles.image} alt="" width="224px" height="182px" />
    </div>
  );
};

export default HomepageTitle2;
